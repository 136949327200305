
import { Component, Vue } from "vue-property-decorator";
import * as FactcheckServices from '@/services/FactCheckService'
import { Factcheck } from "@/types";
import { formatDistance } from "date-fns";
import { nlBE } from "date-fns/locale";
import { ROOT_ACTIONS } from "@/store/actions";
@Component
export default class DeletedFactchecks extends Vue {

    deleted = [] as Factcheck[]

    loadinRestore = '';

    async mounted() {
        this.deleted = await FactcheckServices.GetDeletedFactchecks()
    }

    formatDate(date: string) {
        return formatDistance(new Date(date), new Date(), { addSuffix: true, locale: nlBE },)
    }

    async restoreFactcheck(id: string | undefined) {
        if (!id) return;
        this.loadinRestore = id;
        await FactcheckServices.RestoreFactcheck(id)
        this.deleted = await FactcheckServices.GetDeletedFactchecks()
        this.loadinRestore = '';
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: "info",
            text: "Factcheck hersteld!",
        })
    }

}
